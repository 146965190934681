import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { homeSkillSlider } from "../../constants/swiperConstants";

const TheyTrustUsSlider = () => {
  return (
    <StaticQuery
      query={graphql`
        query Trust {
          allStrapiTrustUs {
            nodes {
              images {
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        const slides = data?.allStrapiTrustUs?.nodes;
        console.log("sagar9", data);
        return (
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="skill-home">
                    <h2 className="commantitel">They trust us</h2>
                  </div>
                </div>
              </div>
            </div>
            <Swiper className="homeskillslider" {...homeSkillSlider}>
              <div className="swiper-wrapper">
                {slides?.map((s) => {
                  return (
                    <SwiperSlide>
                      <div>
                        <img
                          loading="lazy"
                          src={s?.images?.[0]?.url}
                          alt="castrol"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>
          </section>
        );
      }}
    />
  );
};

export default TheyTrustUsSlider;
